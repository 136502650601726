<template>
  <div class="w-full pl-500px">
    <div class="max-w-800 w-full mx-auto py-10">
      <h2 class="text-4xl font-medium text-black-900 mb-8">Join Us</h2>
      <t-alert variant="success" :show="joinUsSuccess" class="my-4">
        Your join us request has been submitted succesfully, we'll contact you
        soon
      </t-alert>
      <t-alert variant="danger" :show="joinUsError.length > 0" class="my-4">
        {{ joinUsError }}
      </t-alert>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="mb-11"
      >
        <div class="grid grid-cols-2 gap-x-8">
          <div class="space-y-6">
            <utils-input
              :validationRules="'required'"
              :inputName="'Full Name'"
              :inputType="'text'"
              :isSubmitted="isSubmitted"
              v-model="data.full_name"
            />

            <utils-input
              :validationRules="'required|email'"
              :inputName="'Email'"
              :inputType="'text'"
              :isSubmitted="isSubmitted"
              v-model="data.email"
            />

            <utils-input
              :validationRules="'required'"
              :inputName="'Phone Number (+6512345678)'"
              :inputType="'text'"
              :isSubmitted="isSubmitted"
              v-model="data.telephone"
            />

            <utils-select
              :validationRules="'required'"
              :inputName="'Account Type'"
              :selectList="[
                { label: 'Publisher', code: 'publisher' },
                { label: 'Reseller', code: 'reseller' },
                { label: 'Trial', code: 'trial' },
              ]"
              :isSubmitted="isSubmitted"
              v-model="data.type_of_account"
            />

            <utils-input
              v-if="
                data.type_of_account === 'publisher' ||
                data.type_of_account === 'reseller'
              "
              :validationRules="'required'"
              :inputName="'Number Of User'"
              :inputType="'number'"
              :isSubmitted="isSubmitted"
              v-model="data.number_of_user"
            />

            <utils-checkbox
              validationRules=""
              inputName="Are you a current SBPA Member?"
              :isSubmitted="isSubmitted"
              :value="data.is_sbpa_member"
              @input="handleIsSbpaMemberChange"
            />

            <utils-checkbox
              v-if="!data.is_sbpa_member"
              validationRules=""
              inputName="Would you like to be a SBPA Member?"
              :isSubmitted="isSubmitted"
              v-model="data.want_become_member"
            />

            <vue-recaptcha
              ref="recaptcha"
              :sitekey="GOOGLE_RECAPTCHA_SITE_KEY"
              @verify="handleVerify"
            ></vue-recaptcha>
          </div>

          <div class="space-y-6">
            <utils-input
              inputName="Organization Name"
              :inputType="'text'"
              :isSubmitted="isSubmitted"
              v-model="data.organization_name"
            />

            <utils-textbox
              validationRules=""
              inputName="Please list out the email(s) your organization used to register ISBN with the National Library Board (Separate by new line)"
              :isSubmitted="isSubmitted"
              v-model="data.email_for_nlb"
            />

            <utils-textbox
              inputName="Remarks"
              :isSubmitted="isSubmitted"
              v-model="data.remarks"
            />

            <utils-input
              v-if="data.type_of_account === 'publisher'"
              validationRules="required"
              inputName="New Titles Per Year"
              inputType="number"
              :isSubmitted="isSubmitted"
              v-model="data.new_titles_per_year"
            />

            <utils-input
              v-if="data.type_of_account === 'publisher'"
              validationRules="required"
              inputName="Number of Titles Published in Singapore (Estimated)"
              inputType="number"
              :isSubmitted="isSubmitted"
              v-model="data.titles_published_in_sg"
            />
          </div>
        </div>

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm"> Submit request</span>
        </base-button>
      </validation-observer>
      <p class="text-black-900 text-sm">
        Already have an account?
        <router-link
          :to="{ name: 'login' }"
          class="text-orange-900 hover:text-grey-700"
        >
          Sign In
        </router-link>
      </p>
      <p class="text-black-900 text-sm mt-2">
        Want to open admin dashboard?
        <a href="/admin" class="text-orange-900 hover:text-grey-700">
          Click here
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";
import UtilsSelect from "@/components/utils/UtilsSelect.vue";
import UtilsCheckbox from "@/components/utils/UtilsCheckbox.vue";
import UtilsTextbox from "@/components/utils/UtilsTextbox.vue";
import { REFRESH_JOIN_US, CREATE_JOIN_US } from "@/store/actions.type";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "@/common/config";

const initialData = {
  full_name: "",
  email: "",
  telephone: "",
  type_of_account: "",
  number_of_user: 0,
  is_sbpa_member: false,
  want_become_member: false,
  organization_name: "",
  email_for_nlb: "",
  remarks: "",
  new_titles_per_year: 0,
  titles_published_in_sg: 0,
  g_recaptcha_response: "",
};

export default {
  name: "AuthJoinUsForm",
  components: {
    "utils-input": UtilsInput,
    "utils-select": UtilsSelect,
    "utils-checkbox": UtilsCheckbox,
    "utils-textbox": UtilsTextbox,
    "base-button": BaseButton,
    ValidationObserver,
    VueRecaptcha,
  },
  created() {
    this.GOOGLE_RECAPTCHA_SITE_KEY = GOOGLE_RECAPTCHA_SITE_KEY;
  },
  data() {
    return {
      isSubmitted: false,
      data: { ...initialData },
    };
  },
  mounted() {
    this.$store.dispatch(REFRESH_JOIN_US);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "joinUsSuccess", "joinUsError"]),
  },
  methods: {
    handleIsSbpaMemberChange(event) {
      this.data.is_sbpa_member = event;
      if (this.data.is_sbpa_member) {
        this.data.want_become_member = false;
      }
    },
    handleVerify(event) {
      this.data.g_recaptcha_response = event;
    },
    handleSubmit() {
      this.$store.dispatch(REFRESH_JOIN_US);

      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        this.$refs.recaptcha.reset();

        if (!valid) {
          return;
        }

        await this.$store.dispatch(CREATE_JOIN_US, this.data);

        if (this.joinUsSuccess) {
          this.data = { ...initialData };
        }
      });
    },
  },
};
</script>
