<template>
  <validation-provider
    :rules="validationRules"
    v-slot="{ errors }"
    mode="passive"
    tag="div"
    class="relative"
  >
    <div class="flex justify-between items-center mb-2">
      <label :for="inputName" class="block text-grey-700 text-sm">{{
        inputName
      }}</label>
      <slot />
    </div>
    <select
      :value="value"
      @input="$emit('input', $event.target.value)"
      :id="inputName"
      class="
        text-base text-black-900
        rounded
        border border-grey-500
        px-2
        py-2
        w-full
        bg-white-900
      "
      :class="{ 'border-red-600': isSubmitted && errors && errors.length }"
    >
      <option
        v-for="(item, index) in selectList"
        :key="index"
        :value="item.code"
      >
        {{ item.label }}
      </option>
    </select>
    <p
      v-if="isSubmitted && errors && errors.length"
      class="absolute left-0 top-full mt-1 text-xs text-red-600"
    >
      {{ errors[0] }}
    </p>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "UtilsSelect",
  components: {
    ValidationProvider,
  },
  props: {
    validationRules: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
    selectList: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
